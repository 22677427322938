export const partColors = {
  0: 'light-violet',
  1: 'green',
  2: 'dark-orange',
  3: 'light-orange',
  4: 'yellow',
  5: 'pink',
  6: 'violet',
  7: 'light-blue',
  8: 'turquoise',
  9: 'light-green',
  10: 'part10-light-blue',
  11: 'purple',
  12: 'pale-pink',
  13: 'sqlgreen',
};
